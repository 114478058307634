import React, {useState} from "react";
import {Table, Column, AutoSizer} from "react-virtualized";
import {getRegionFlag, getPrio, getStatusColor} from "../../_utils/helper";
import "react-virtualized/styles.css"; // only needs to be imported once
import AsignationControl from "../../components/asignationControl";
import ServicesRequest from "../../components/ServicesRequest";
import {getShortStatus} from "../../_utils/helper";

import {
  tableStyle,
  checkboxColumn,
  orderColumn,
  nameColumn,
  retailersColumn,
  priorityColumn,
  regColumn,
  missingDataColumn,
  articleStatusColumn,
  estatusColumn,
  upcColumn,
  skuColumn,
  marcaColumn,
  asignColumn,
  servicesColumn,
} from "./utils/tableStyle";
import StatusOrders from "../../components/statusTable/StatusOrders";
import GenericModal from "../../components/GenericModal";
import sucessModal from "../../assets/IconComponents/downLoadModalIcons/downloadSucces.svg";
import errorModal from "../../assets/IconComponents/downLoadModalIcons/downloadFailed.svg";

const TableTasks = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const user = JSON.parse(sessionStorage.getItem("user"));
  const roles = [1, 6];
  return (
    <>
      <div style={{width: "100%", height: "70vh"}}>
        <AutoSizer>
          {({width, height}) => (
            <Table
              {...tableStyle}
              rowClassName="table-row"
              headerHeight={40}
              width={width}
              height={height}
              rowHeight={40}
              onRowClick={(evt) => {
                props.rowClicked(evt.event.target.id, evt.rowData, evt.index);
              }}
              rowCount={props.articlesList.length}
              rowGetter={({index}) => props.articlesList[index]}
            >
              <Column
                width={width * 0.2}
                {...checkboxColumn}
                label={
                  <input
                    id={"globalHeaderCheckbox"}
                    onClick={(evt) => {
                      evt.stopPropagation();
                      props.checkAllItems(evt.target.checked);
                    }}
                    type="checkbox"
                  />
                }
                cellRenderer={({rowIndex, rowData}) => {
                  return (
                    <input
                      key={`checkbox-${rowIndex}-${rowData.checked}`}
                      type="checkbox"
                      defaultChecked={rowData.checked}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        props.checkboxClicked(
                          evt.target.checked,
                          rowData,
                          props.productsSelected
                        );
                      }}
                    />
                  );
                }}
              />
              <Column
                label="Reg."
                {...regColumn}
                width={width * 0.2}
                cellRenderer={({rowData}) => (
                  <figure>
                    <img
                      style={{
                        height: 30,
                        width: 30,
                        objectFit: "contain",
                      }}
                      src={getRegionFlag(rowData.article.country)}
                      alt={rowData.article.country}
                    />
                  </figure>
                )}
              />

              <Column label="Orden" {...orderColumn} width={width * 0.2} />
              <Column
                label="UPC"
                {...upcColumn}
                width={width * 0.2}
                cellDataGetter={({rowData}) => rowData.article.upc}
              />
              <Column
                label="SKU"
                {...skuColumn}
                width={width * 0.2}
                cellDataGetter={({ rowData }) => rowData.article.sku ? rowData.article.sku : "-"}
              />

              <Column
                label="Nombre"
                {...nameColumn}
                width={width * 0.2}
                cellDataGetter={({rowData}) => rowData.article.name}
              />
              <Column
                label="Estatus"
                {...articleStatusColumn}
                width={width * 0.2}
                cellRenderer={({rowData}) => (
                  <StatusOrders
                    borderRadius="3px"
                    color={getStatusColor(rowData.status)}
                    status={getShortStatus(rowData.status)}
                  />
                )}
              />
              <Column
                label="Cad."
                {...retailersColumn}
                width={width * 0.2}
                cellDataGetter={({rowData}) => rowData.retailers.length}
              />
              <Column
                label="E.D."
                {...asignColumn}
                width={width * 0.2}
                cellRenderer={({rowData}) => (
                  <AsignationControl
                    key={`assign-control-ED-${rowData?.orderId}-${rowData?.article?.id_datasheet_especialist}`}
                    assignUser={props.assignUser}
                    target={"especialist"}
                    concept={"datasheet"}
                    item={rowData}
                    userGroup={props.usersByRole[0] || []}
                  />
                )}
              />
              <Column
                label="F.T."
                {...estatusColumn}
                width={width * 0.2}
                cellRenderer={({rowData}) => (
                  <StatusOrders
                    borderRadius="30px"
                    color={getStatusColor(rowData.datasheet_status)}
                    status={getShortStatus(rowData.datasheet_status)}
                  />
                )}
              />
              <Column
                label="E.Ds."
                {...asignColumn}
                width={width * 0.2}
                cellRenderer={({rowData}) => (
                  <AsignationControl
                    key={`assign-control-EDs-${rowData?.orderId}-${rowData?.article?.id_description_especialist}`}
                    assignUser={props.assignUser}
                    target={"especialist"}
                    concept={"description"}
                    item={rowData}
                    userGroup={props.usersByRole[0] || []}
                  />
                )}
              />
              <Column
                label="Desc"
                {...estatusColumn}
                width={width * 0.2}
                cellRenderer={({rowData}) => (
                  <StatusOrders
                    borderRadius="30px"
                    color={getStatusColor(rowData.description_status)}
                    status={getShortStatus(rowData.description_status)}
                  />
                )}
              />
              <Column
                label="E.I."
                {...asignColumn}
                width={width * 0.2}
                cellRenderer={({rowData}) => (
                  <AsignationControl
                    key={`assign-control-Imgs-${rowData?.orderId}-${rowData?.article?.id_images_especialist}`}
                    assignUser={props.assignUser}
                    target={"especialist"}
                    concept={"images"}
                    item={rowData}
                    userGroup={props.usersByRole[1] || []}
                  />
                )}
              />
              <Column
                label="Img"
                {...estatusColumn}
                width={width * 0.2}
                cellRenderer={({rowData}) => (
                  <StatusOrders
                    borderRadius="30px"
                    color={getStatusColor(rowData.images_status)}
                    status={getShortStatus(rowData.images_status)}
                  />
                )}
              />
              <Column
                label="Q.A."
                {...asignColumn}
                width={width * 0.2}
                cellRenderer={({rowData}) => (
                  <AsignationControl
                    key={`assign-control-QA-${rowData?.orderId}-${rowData?.article?.id_auditor}`}
                    assignUser={props.assignUser}
                    target={"auditor"}
                    concept={"auditor"}
                    item={rowData}
                    userGroup={props.usersByRole[4] || []}
                    shownLeft
                  />
                )}
              />
              {roles.includes(user.id_role) && (
                <Column
                  label="Cont."
                  {...servicesColumn}
                  width={width * 0.3}
                  cellRenderer={({rowData, rowIndex}) => (
                    <ServicesRequest
                      key={`services-${rowData?.orderId}-${rowData?.article?.id_article}`}
                      articleId={rowData.article.id_article}
                      upc={rowData.article.upc}
                      orderId={rowData.orderId}
                      status={rowData.services}
                      retailers={rowData.retailers}
                      article={rowData}
                      setShowModal={setShowModal}
                      setModalData={setModalData}
                      articlesLists={props.articlesLists}
                      setArticlesLists={props.setArticlesLists}
                      rowIndex={rowIndex}
                    />
                  )}
                />
              )}
            </Table>
          )}
        </AutoSizer>
      </div>
      {showModal && (
        <GenericModal
          message={modalData.message}
          detail={modalData.detail !== "" && modalData.detail}
          img={modalData.img === true ? sucessModal : errorModal}
          close={() => {
            setShowModal(false);
          }}
          button1={{
            name: "Aceptar",
            action: () => {
              setShowModal(false);
            },
          }}
        />
      )}
    </>
  );
};
export default TableTasks;
